import React from "react"
import { Link } from 'gatsby'
import Logo from "../../../static/images/logo/logo.svg"
import Seo from "../../components/seo"
import BookHeader from "../../components/bookPageHeader"
import BookFrom from "../../components/bookForm"
import ReactBook from "../../../static/images/commonImg/reactbook.svg"
//import ReactBookTS from "../../../static/images/commonImg/reactbook-ts.svg"
import ReactBook2 from "../../../static/images/commonImg/reactbook2.svg"
import ReactBook2TS from "../../../static/images/commonImg/reactbook2-ts.svg"
import NodeExpressBook from "../../../static/images/commonImg/nodebook-intro.svg"
import NodeExpressBook2 from "../../../static/images/commonImg/nodebook-advanced.svg"
import NextBook2a from "../../../static/images/commonImg/nextbook2-1.svg"
import NextBook2aTS from "../../../static/images/commonImg/nextbook2-1ts.svg"
import MernBook from "../../../static/images/commonImg/mern-book-1.svg"
import GatsbyBook from "../../../static/images/commonImg/gatsbybook.svg"
import NextBook from "../../../static/images/commonImg/nextbook.svg"
import JamstackBook from  "../../../static/images/commonImg/jamstackbook.svg"
import vuebook from "../../../static/images/commonImg/vuebook.svg"
import NuxtBook from "../../../static/images/commonImg/nuxtbook.svg"
import JSBook from "../../../static/images/commonImg/jsbook.svg"
import ProxyBook from "../../../static/images/commonImg/proxyserver-book.png"
import NextServerActionsBook from "../../../static/images/commonImg/nextjs-server-actions.svg"
import ReactMasterBook from "../../../static/images/commonImg/react-master-book.svg" 
import NextFullAppFolder1 from "../../../static/images/commonImg/nextbook-app-fullstack-1.svg"
import TSmasterBook from "../../../static/images/commonImg/typescript-master-book.svg"
import RegisterNewsLetterBody from "../../components/registerNewsLetter"

// REVIW: https://www.amazon.co.jp/review/create-review/?ie=UTF8&channel=glance-detail&asin=B0BBMQS28M

const generalData = {
    title: "はじめてつくるReactアプリ with TypeScript（Vite）",
    updated: "2024/08/12",
    interview1: "https://monotein.com/blog/interview-about-my-books",
    urlToApp: "https://react-typescript-book-with-vite.netlify.app",
    urlToCode1: "https://github.com/mod728/react-typescript-book-weather-app-with-vite",
    urlToCode2: "",
    reviewLink1: "https://www.amazon.co.jp/review/create-review/?channel=glance-detail&asin=B094Z1R281",
    reviewLink2: ""
}

const booksData = [
    {oldPrice: "", newPrice: "", newRelease: "*2024年2月発売", title: "React全部入り：Reactマスター", img: ReactMasterBook, url: "https://monotein.base.shop/p/00001", description: "Reactを『なんとなく』は使える人が、2日で自信を持ってReactを書ける本！<br/><br/>JavaScriptの基礎の基礎、Reactの基本概念、そして応用までの全範囲をカバーした待望の解説書。<br/><br/>React入門に成功したビギナーが、たしかな理解とスキルを身につけて、自走できるようになる一冊。"},
    {oldPrice: "", newPrice: "", newRelease: "*2024年3月発売", title: "React + TS徹底フォーカス：TypeScriptマスター with React", img: TSmasterBook, url: "https://monotein.base.shop/p/00002", description: "2日でTypeScript + Reactの苦手意識を捨てされる本。<br/><br/>「TypeScriptをReactで使う」という点にフォーカスした貴重なハンズオン（作りながら学ぶ）形式の実用書。<br/><br/>TypeScriptに苦手意識を持っていても、自信をもってReact + TypeScript開発を進められるようになります。"},
    {oldPrice: "", newPrice: "", newRelease: "*New Release* 2024年11月発売", title: "Next.js Server Actionsでつくるフルスタックアプリ", img: NextServerActionsBook, url: "https://www.amazon.co.jp/dp/B0DMQLP8Z9", description: "Next.jsビギナー向けの入門書。<br/><br/>Next.jsとServer Actionsを使って、ログイン機能をもった本格的なアプリケーション開発の方法を紹介。<br/><br/>2024年10月にリリースされたばかりの最新のNext.jsバージョン15使用。"},
]

const booksDataForSlider = [
    {newRelease: "", title: "", img: ReactBook2, url: "https://www.amazon.co.jp/dp/B09693ZJCV", description: ""},
    {newRelease: "", title: "", img: ReactBook2TS, url: "https://www.amazon.co.jp/dp/B09Q57Q9T1", description: ""},
    {newRelease: "", title: "", img: NodeExpressBook, url: "https://www.amazon.co.jp/dp/B09HQZ1BW8", description: ""},
    {newRelease: "", title: "", img: NodeExpressBook2, url: "https://www.amazon.co.jp/dp/B09X56PSCQ", description: ""},
    {newRelease: "", title: "", img: ReactBook, url: "https://www.amazon.co.jp/dp/B08XWMVX76", description: ""},
    {newRelease: "", title: "", img: GatsbyBook, url: "https://www.amazon.co.jp/dp/B0BNQBDHFH", description: ""},
    {newRelease: "", title: "", img: JamstackBook, url: "https://www.amazon.co.jp/dp/B094Z1R281", description: ""},
    {newRelease: "", title: "", img: NextBook2a, url: "https://www.amazon.co.jp/dp/B09YWSHR58", description: ""},
    {newRelease: "", title: "", img: JSBook, url: "https://www.amazon.co.jp/dp/B0C9C9SVFG", description: ""},
    {newRelease: "", title: "", img: NuxtBook, url: "https://www.amazon.co.jp/dp/B0B5N8QJ2Y", description: ""},
    {newRelease: "", title: "", img: ProxyBook, url: "https://www.amazon.co.jp/dp/B0BBMQS28M", description: ""},
]

const links = [
    {
        chapterNum: 1,
        data: [
            {name: "Node.js", url: "https://nodejs.org/en/download"},
            {name: "VS Code", url: "https://code.visualstudio.com/download"},
            {name: "エラー発生時の対処方法", url: "https://monotein.com/blog/how-to-find-and-fix-errors"},
        ]
    },
    {
        chapterNum: 2,
        data: [
            {name: "create-react-appを使ったコード（参考）", url: "https://github.com/mod728/react-book-typescript-weather-app"},
            {name: "weatherapi", url: "https://www.weatherapi.com"},
            {name: "背景画像", url: "https://github.com/mod728/react-typescript-book-weather-app-with-vite/blob/master/src/assets/background-image.jpg"},
            {name: "CSSファイル", url: "https://github.com/mod728/react-typescript-book-weather-app-with-vite/blob/master/src/index.css"},
            {name: "第2章完了時のソースコード", url: "https://github.com/mod728/react-typescript-book-weather-app-with-vite/tree/chapter2"},
        ]
    },
    {
        chapterNum: 3,
        data: [
            {name: "Netlify", url: "https://www.netlify.com"},
        ]
    },
    {
        chapterNum: 4,
        data: [
            {name: "第4章完了時のソースコード", url: "https://github.com/mod728/react-typescript-book-weather-app-with-vite"},
            {name: "アマゾンレビューURL", url: generalData.reviewLink1},
      /*       {name: "", url: ""},
            {name: "*読者限定　無料資料", url: "https://monotein.com/present-for-readers"} */
        ]
    },
]

const ReactBookTSLinks = () => (
    <div className="container2">
        <Seo title={generalData.title} noindex={true} />
        <div><Link to="/blog"><img src={Logo} style={{width: "180px", margin: "auto", display: "block", paddingTop: "25px"}}/></Link></div>
        <div style={{textAlign: "center", marginTop: "16px"}}><a rel="noopener noreferrer" target="_blank" href="/blog">フロントエンド情報ブログ</a><span> || </span><a rel="noopener noreferrer" target="_blank" href="/business">著者について</a></div>
        <h3>『{generalData.title}』リンクページ</h3>
        <h4>更新日：<span>{generalData.updated}</span></h4>
        <BookHeader/>
        <ul>
            <h3 style={{backgroundColor: "#525252", display: "inline-block", color: "#fff", padding: "0 40px"}}>全般</h3>
            <br/><br/><br/>
            <li><span style={{color: "red"}}>*weatherAPIのサイトが利用できない場合について（2024年8月12日）</span>：<a rel="noopener noreferrer" target="_blank" href="https://monotein-books.netlify.app/reactbook1-vite-ts-alternative-api">解説ページへ移動する</a></li>
            <br/><br/><br/>
            {generalData.urlToApp && <li>見本アプリ：<a rel="noopener noreferrer" target="_blank" href={generalData.urlToApp}>{generalData.urlToApp}</a></li>}
            <li>ソースコードダウンロード：<a rel="noopener noreferrer" target="_blank" href={generalData.urlToCode1}>{generalData.urlToCode1}</a></li>
            {generalData.urlToCode2 && 
                 <li>ソースコードダウンロード（後編）：<a rel="noopener noreferrer" target="_blank" href={generalData.urlToCode2}>{generalData.urlToCode2}</a></li>
            }
            <li><span className="amazon-review">Amazonでレビューする</span>：<a rel="noopener noreferrer" target="_blank" href={generalData.reviewLink1}>{generalData.reviewLink1}</a></li>
            {generalData.reviewLink2 && 
                <li><span className="amazon-review">Amazonでレビューする（後編）</span>：<a rel="noopener noreferrer" target="_blank" href={generalData.reviewLink2}>{generalData.reviewLink2}</a></li>
            }
        </ul>

        <div className="book-wrapper">
            <div className="gridContainer">
                {booksData.map((x, index) => 
                    <div className="imgTextContainer" key={index}>
                        <a rel="noopener noreferrer" target="_blank" href={x.url}>
                            <img src={x.img} alt="cover"/>
                        </a>
                        <div>
                            {x.newRelease && <p className="new-release">{x.newRelease}</p>}
                            <br/>
                            {x.oldPrice && <p className="new-release-price">リリース記念特価（期間限定）<span>{x.oldPrice}円</span> ➡ {x.newPrice}円</p>}
                            <h3>『{x.title}』</h3>
                            {x.url.includes("monotein.base.shop") ? 
                                <a rel="noopener noreferrer" target="_blank" href={x.url}><p>→もっとくわしく見る</p></a>
                            :  
                                <a rel="noopener noreferrer" target="_blank" href={x.url}><p>Amazonで見る</p></a>
                            }
                            <p dangerouslySetInnerHTML={{ __html: x.description }}/>
                        </div>
                    </div>
                )}
            </div>
        </div>

        <ul>
            {links.map((x, index) => 
                <div key={index}>
                    <h3>{typeof x.chapterNum === "string" ? x.chapterNum : `第${x.chapterNum}章`}</h3>
                    {x.data.map((y, index) => 
                        <li key={index}>{y.name}： <a rel="noopener noreferrer" target="_blank" href={y.url}>{y.url}</a></li>
                    )}
                </div>
            )}
        </ul>
        <div style={{marginTop: "90px" }}></div>
        <RegisterNewsLetterBody 
                fromWhere="ReactBook1TS-vite"
                backendUrl="https://us-central1-nov182021.cloudfunctions.net/server/news-letter"
                text={<p>React、Next.js、TypeScriptなど最新のウェブ開発のお役立ち情報を、ビギナー向けにかみ砕いて無料配信中。<br/>（*配信はいつでも停止できます）</p>}
        />
    </div>
)

export default ReactBookTSLinks
